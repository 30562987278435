@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin align($vertical: true, $horizontal: true) {
	position: relative;
	@if $vertical {
		top: 50%;
	}
	@if $horizontal {
		left: 50%;
	}
	@include transform(
		translate(if($horizontal, -50%, 0), if($vertical, -50%, 0))
	);
}

@mixin absoluteAlign($vertical: true, $horizontal: true) {
	position: absolute;
	@if $vertical {
		top: 50%;
	}
	@if $horizontal {
		left: 50%;
	}
	@include transform(
		translate(if($horizontal, -50%, 0), if($vertical, -50%, 0))
	);
}

@mixin fullscreen($position: absolute) {
	position: $position;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@mixin darkScrollbar() {
	&::-webkit-scrollbar-track {
		border-radius: 8px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	&::-webkit-scrollbar {
		width: 8px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #000;
	}
}

@mixin lightScrollbar() {
	&::-webkit-scrollbar-track {
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 0.2);
	}

	&::-webkit-scrollbar {
		width: 4px;
		background-color: rgba(255, 255, 255, 0.2);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #fff;
	}
}

@mixin fullscreenPseudo($position: absolute) {
	content: "";
	@include fullscreen($position);
}

@mixin size($height, $width) {
	height: $height;
	min-height: $height;
	width: $width;
	max-width: $width;
	min-width: $width;
}

@mixin flexbox($direction: row) {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: $direction;
}

@mixin flex($justify: space-between, $align: center, $direction: column) {
	display: flex;
	justify-content: $justify;
	align-items: $align;
	flex-direction: $direction;
}

@mixin filter($property) {
	filter: blur($property);
	-webkit-filter: blur($property);
	-moz-filter: blur($property);
	-o-filter: blur($property);
}

@mixin transform($property) {
	transform: $property;
	-webkit-transform: $property;
	-moz-transform: $property;
	-o-transform: $property;
}

@mixin transition($property, $duration, $function) {
	-webkit-transition: $property $duration $function;
	-moz-transition: $property $duration $function;
	-o-transition: $property $duration $function;
	transition: $property $duration $function;
}
