@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('./fonts/Gilroy-ExtraBold.otf');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('./fonts/Gilroy-Light.otf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('./fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'PermanentMarker';
  src: url('./fonts/PermanentMarker.ttf');
}

@font-face {
  font-family: 'Chantal-Bold';
  src: url('./fonts/Chantal-Bold-Italic.woff');
}

@font-face {
  font-family: 'Chantal-Medium-Italic';
  src: url('./fonts/Chantal-Medium-Italic.woff');
}
