$primary-text-color: #2a2a2a;
$light-background: #eaedee;
$dark-background: #484848;

$linear-gradient: linear-gradient(88deg, #ff0 -22%, #b3fe00 98%, #aafe00 98%);

$font-regular: Gilroy-Light, Arial, Helvetica, sans-serif;
$font-regular2: Roboto-Regular, Arial, Helvetica, sans-serif;
$font-bold: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
$font-bold2: Roboto-Bold, Arial, Helvetica, sans-serif;
$text-xl: 72px;
$text-lg: 64px;
$text-md: 38px;
$text-sm: 20px;
$text-xs: 14px;

$default-width: 1200px;
$default-mobile-width: 100vw;
$medium-width-1: 1090px;
$medium-width-2: 980px;
$medium-width-3: 680px;
$tablet-large: 1200px;
$tablet-medium: 850px;
$mobile-large: 500px;
$mobile-medium: 380px;
$mobile-small: 320px;
$landscape-height-large: 500px;
$landscape-width-large: 1000px;
$landscape-height-medium: 380px;
$landscape-width-medium: 730px;
$landscape-height-small: 260px;

$workout-image-width: 379px;
$workout-image-height: 214px;

$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

$px20: 1.04vw;
$px16: 0.833vw;
$px14: 0.729vw;
