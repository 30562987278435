@import "./variables.scss";
@import "./mixins.scss";

.App {
	text-align: center;
	color: #fff;
	position: relative;
	height: 100%;
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;

	&.mobile {
		-webkit-overflow-scrolling: touch;
		overflow: hidden;
	}
}

button {
	outline: 0;
}

a {
	color: #fff;
	text-decoration: none;
}

::selection {
	background: #ffff00;
}

.App.rtl {
	direction: rtl;
}

.camera-feed canvas {
	transform: scaleX(-1);
}

.camera-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.camera-background canvas {
	left: 50%;
	position: relative;
	width: 100%;
	transform: translateX(-50%) scaleX(-1);
}

.camera-background-overlay {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(15, 16, 22, 0.9);
	height: 100%;
	width: 100%;
}

.button {
	background-image: linear-gradient(
		84deg,
		#ffff00 -21%,
		#b3fe00 96%,
		#aafe00 96%
	);
	color: #1e353e;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	padding: 18px 50px;
	border-radius: 30px;
	width: fit-content;
	cursor: pointer;
	position: relative;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

	&.auto-width {
		width: auto;
	}
}

.button svg {
	margin-right: 10px;
}

.mobile .button {
	font-size: 16px;
	margin: auto;
	padding: 15px 35px;
}

.button:not(.button-loading):not(.no-glow):before {
	content: "";
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	width: 100%;
	opacity: 0.4;
	border-radius: 34px;
	filter: blur(13px);
	background-image: linear-gradient(
		89deg,
		#ffff00 2%,
		#b3fe00 98%,
		#aafe00 98%
	);
	z-index: -1;
}

.button.button-loading:after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 34px;
}

.button .button-loading {
	position: absolute;
	border: 5px solid rgba(255, 255, 255, 0.5);
	border-top: 5px solid #fff;
	border-radius: 50%;
	height: 1vw;
	animation: spin 2s linear infinite;
	width: 1vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 0 0;
	z-index: +1;
}

@keyframes spin {
	0% {
		transform: rotate(0deg) translate(-50%, -50%);
	}
	100% {
		transform: rotate(360deg) translate(-50%, -50%);
	}
}

.button.light {
	border: solid 1px $primary-text-color;
	color: #1e353e;
	background: none;
}

.button.light:before {
	display: none;
}

.button.dark {
	border: solid 1px #fff;
	color: #fff;
	background: none;
}

.button.dark:before {
	display: none;
}

.button.disabled {
	cursor: default;
	opacity: 0.5;
}

.button.secondary {
	background: none;
	border-color: transparent;
	text-decoration: underline;
	font-family: OpenSans-Regular;
	padding-right: 30px;
	padding-left: 30px;
}

.button.dark.secondary {
	color: #fff;
}

.button.light.secondary {
	color: #808d9b;
}

.button.no-padding {
	padding: 0;
}

.button.narrow {
	padding: 11px 25px;
	font-size: 14px;

	svg {
		height: 20px;
	}
}

.button {
	.tooltip {
		visibility: hidden;
		width: max-content;
		background-color: #000;
		color: #fff;
		text-align: center;
		padding: 5px;
		border-radius: 6px;
		position: absolute;
		top: -75%;
		z-index: 1;

		&:after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: black transparent transparent transparent;
		}
	}

	&:hover .tooltip {
		visibility: visible;
	}
}

.button2 {
	color: #fff;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	padding: 18px 50px;
	border-radius: 30px;
	width: fit-content;
	cursor: pointer;
	position: relative;
	border: 1px solid #fff;
	margin: 2vh 0;
	font-size: 1vw;
}

.button2:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.button3 {
	color: $primary-text-color;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	padding: 18px 50px;
	border-radius: 30px;
	width: fit-content;
	cursor: pointer;
	position: relative;
	border: 1px solid $primary-text-color;
	margin: 2vh 0;
	font-size: 1vw;
}

.button3:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.button4 {
	color: #fff;
	background-color: $primary-text-color;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	padding: 18px 50px;
	border-radius: 30px;
	width: fit-content;
	cursor: pointer;
	position: relative;
	border: 1px solid #fff;
	margin: 2vh 0;
	font-size: 1vw;
}

.button4:hover {
	background-color: rgba(0, 0, 0, 0.9);
}

.button5 {
	color: $primary-text-color;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	padding: 18px 30px;
	border-radius: 30px;
	width: fit-content;
	cursor: pointer;
	position: relative;
	margin: 2vh 0;
	font-size: 1vw;
}

.button5:hover {
	text-decoration: underline;
}

.video-loading-placeholder {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: +1;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.1);
}

.video-play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -40%);
	cursor: pointer;
	width: 90px;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(15, 16, 22, 0.4);
}

.social-login .divider {
	position: relative;
	// opacity: 0.5;
	margin: $px20 0;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #808d9b;
}

.social-login .divider:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 80%;
	transform: translate(-50%, -50%);
	height: 1px;
	width: 95px;
	background-color: #000;
	opacity: 0.3;
	z-index: -1;
}

.social-login .divider:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 20%;
	transform: translate(-50%, -50%);
	height: 1px;
	width: 95px;
	background-color: #000;
	opacity: 0.3;
	z-index: -1;
}

.social-login-button {
	color: #fff;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 16px 0;
	border-radius: 30px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.social-login-button.button-loading {
	pointer-events: none;
}

.social-login-button.button-loading:after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 1.77vw;
}

.social-login-button svg {
	height: 17px;
	width: 17px;
	margin-right: 0.78vw;
	margin-left: 20px;
}

.social-login-button.google {
	background-color: #f5513f;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: #c44031;
	}
}

.social-login-button.facebook {
	background-color: #0079fa;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: #065db9;
	}
}

// .social-login-button.icon {
//   background-color: black;  //change here
// }

.social-login-button .button-loading {
	position: absolute;
	border: 5px solid rgba(255, 255, 255, 0.5);
	border-top: 5px solid #fff;
	border-radius: 50%;
	height: 1vw;
	animation: spin 2s linear infinite;
	width: 1vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 0 0;
	z-index: +1;
}

.RegisterForm,
.LoginForm {
	.button {
		padding: 15px 50px;
		font-size: 16px;
		transition: all 0.3s ease-in-out;

		&:hover {
			box-shadow: -1px 20px 56px -11px rgba(197, 254, 0, 0.54);
			-webkit-box-shadow: -1px 20px 56px -11px rgba(197, 254, 0, 0.54);
			-moz-box-shadow: -1px 20px 56px -11px rgba(197, 254, 0, 0.54);
		}
	}
}

.LoginForm {
	height: 230px;

	// @media screen and (max-width: 1300px) {
	// 	height: 205px;
	// }

	.button {
		top: 25px;
	}
}

.bottom {
	position: relative;

	@media (max-width: $mobile-medium) and (max-height: 600px) {
	}

	.switch {
		font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: #808d9b;

		span {
			font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
			color: #1b1c21;
			cursor: pointer;
		}
	}

	.button {
		margin-top: 14px;
		top: 0 !important;
		padding: 10px 50px;
		transition: all 0.5s ease;
		font-size: 16px;
		z-index: 100;

		&:hover {
			box-shadow: -1px 12px 56px -11px rgba(0, 0, 0, 0.54);
			-webkit-box-shadow: -1px 12px 56px -11px rgba(0, 0, 0, 0.54);
			-moz-box-shadow: -1px 12px 56px -11px rgba(0, 0, 0, 0.54);
		}
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg) translate(-50%, -50%);
	}
	100% {
		transform: rotate(360deg) translate(-50%, -50%);
	}
}

.timeout-bar {
	position: absolute;
	top: 20px;
	left: 13px;
	right: 13px;
	height: 10px;
	z-index: 1000;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.timeout-bar .section {
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 10px;
	height: 100%;
	margin: 0 9px;
}

.timeout-bar .bar {
	position: relative;
	top: 0;
	background-color: #fff;
	height: 100%;
	border-radius: 20px;
}

.mobile-menu .wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.mobile-menu .user {
	margin-top: 20vh;
	font-size: 25px;
}

.mobile-menu .user img {
	border-radius: 50%;
	height: 70px;
	margin-bottom: 10px;
}

.mobile-menu .title {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 40px;
	margin-top: 20px;
}

.mobile-menu .buttons {
	margin: 30px 0;
	display: grid;
	align-items: center;
	justify-content: center;
}

.mobile-menu .button {
	width: auto;
	margin-bottom: 10px;
}

.system-setup .logo {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 10%;
}

.system-setup .background {
	object-fit: cover;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
	width: 100%;
}

.system-setup .camera-permission .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 3vh;
	padding: 0;
	text-align: center;
	overflow: hidden;
	width: 425px;
	height: 485px;
	padding-bottom: 30px;
}

.system-setup .camera-permission .waiting-for-camera {
	@include flexbox(column);
	height: 100%;

	.title {
		color: #fff;
		font-size: 24px;
		font-family: $font-bold;
		margin-bottom: 10px;
	}

	.subtitle {
		font-size: 16px;
		width: 325px;
		line-height: 1;
	}

	.not-recording-disclaimer {
		position: absolute;
		bottom: 30px;
		border-radius: 12px;
		background-color: #fff;
		width: max-content;
		height: 80px;
		padding: 23px 36px;
		text-align: left;
		opacity: 0;
		line-height: 1.5;

		.disclaimer-title {
			font-family: $font-bold;
			font-size: 24px;
			color: #0f1016;

			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		.disclaimer-description {
			font-size: 18px;
			color: #0f1016;
			font-family: Roboto-Light, Arial, Helvetica, sans-serif;
		}
	}
}

.system-setup .camera-permission .container .instruction-video {
	width: 100%;
}

.system-setup .camera-permission .title {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 24px;
	color: #0f1016;
	margin: 0 28px;
	margin-bottom: 22px;
	margin-top: 24px;
}

.system-setup .camera-permission .subtitle {
	color: #808d9b;
	font-size: 16px;
	font-family: Roboto-Light, Arial, Helvetica, sans-serif;
	margin: 0 27px;
	margin-bottom: 14px;
	line-height: 1.5;

	@media screen and (max-width: $mobile-large) {
		margin: 0 0 15px;
	}
}

.system-setup .camera-permission img {
	height: 20vh;
	margin: 8vh 0;
}

.system-setup .camera-permission .button.secondary {
	font-size: 14px;
}

.system-setup .camera-permission .buttons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.system-setup .ai-compiltability .description {
	margin-top: 30px;
}

.system-setup .ai-compiltability .image {
	width: 70%;
}

.system-setup .ai-compiltability .link {
	display: flex;
	align-items: center;
	justify-content: center;
}

.system-setup .ai-compiltability .copied-caption {
	position: absolute;
	bottom: 70px;
	left: 50%;
	transform: translateX(-50%);
	z-index: +1;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 1vh;
	border-radius: 1vh;
	opacity: 0;
	color: #fff;
}

.system-setup .ai-compiltability .loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 4vh;
}

.system-setup .ai-compiltability .loading:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 20vh;
	width: 20vh;
	background-color: #ffff00;
	z-index: -1;
	border-radius: 50%;
	opacity: 0.5;
	animation: loading 1s infinite;
}

@keyframes loading {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}
	50% {
		transform: translate(-50%, -50%) scale(1.1);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}

.settings .checkbox {
	margin: 10px 0;
}

.Navbar .record-permission {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.Navbar .record-permission:before {
	content: "";
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.Navbar .record-permission .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 2vh;
	padding: 4vh;
	text-align: center;
	width: 25vw;
	height: auto;
	color: $primary-text-color;
}

.Navbar .record-permission img {
	height: 10vh;
}

.Navbar .record-permission .title {
	font-size: 1.5vw;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.Navbar .record-permission .text {
	font-size: 1vw;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.Navbar .record-permission .buttons {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	width: fit-content;
	margin-top: 5vh;
	align-items: baseline;
}

.Navbar .record-permission .buttons .button {
	margin: 0 0.5vw;
	height: 100%;
}

@keyframes heartbeat {
	0% {
		transform: translateX(-50%) scale(0.35);
	}
	20% {
		transform: translateX(-50%) scale(0.6);
	}
	40% {
		transform: translateX(-50%) scale(0.35);
	}
	60% {
		transform: translateX(-50%) scale(0.6);
	}
	80% {
		transform: translateX(-50%) scale(0.35);
	}
	100% {
		transform: translateX(-50%) scale(0.35);
	}
}

.Navbar .record-permission .heart {
	position: absolute;
	width: 100px;
	height: 90px;
	top: 5vh;
	left: 50%;
	transform: translateX(-50%);
	animation: heartbeat 1s infinite;
	opacity: 0;
}

.Navbar .record-permission .heart:before,
.Navbar .record-permission .heart:after {
	position: absolute;
	content: "";
	left: 50px;
	top: 0;
	width: 50px;
	height: 80px;
	background: #000;
	border-radius: 50px 50px 0 0;
	transform: rotate(-45deg);
	transform-origin: 0 100%;
}

.Navbar .record-permission .heart:after {
	left: 0;
	transform: rotate(45deg);
	transform-origin: 100% 100%;
}

.settings .support {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
}

.settings .support a {
	color: #d1ff02;
	text-decoration: underline;
}

.category-display {
	position: relative;
	margin-bottom: 5vh;
}

.category-display .category-name {
	text-align: left;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 25px;
}

.category-display .category-description {
	text-align: left;
	color: rgba(255, 255, 255, 0.5);
	font-size: Roboto-Regular, Arial, Helvetica, sans-serif;
}

.category-display .category-workouts {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	margin-top: 2vh;
	padding-top: 2vh;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 30px;
	overflow: hidden;
}

.category-display .show-all-button {
	opacity: 0.5;
	border: 1px solid #fff;
	width: fit-content;
	border-radius: 20px;
	padding: 5px 10px;
	padding-right: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.category-display .show-all-button:hover {
	opacity: 1;
}

.category-display .show-all-button svg {
	margin-right: 5px;
}

.menu-screen .title {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 70%;
	font-size: 72px;
}

.menu-screen .menu-options-container {
	position: absolute;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	height: 70vh;
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 30px;
	align-items: center;
}

.menu-screen .menu-options-container .menu-option {
	position: relative;
	height: 20vw;
	width: 20vw;
	transition: width 1s ease, height 1s ease;
}

.menu-screen .menu-options-container .menu-option .menu-option-title {
	position: absolute;
	top: 5%;
	left: 5%;
	font-size: 3.5vw;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	line-height: 1;
}

.menu-screen .menu-options-container .menu-option .background {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	background-position: top;
	background-size: cover;
}

.menu-screen .menu-options-container .menu-option:hover {
	height: 25vw;
	width: 25vw;
}

.menu-screen .menu-options-container .hover .menu-option {
	height: 25vw;
	width: 25vw;
}

.menu-screen .menu-options-container .menu-option .background:before {
	content: "";
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
	width: 90%;
	height: 100%;
	filter: blur(50px);
	background-color: #f8e71c;
	opacity: 0;
	transition: all 1s ease;
}

.menu-screen .menu-options-container .menu-option:hover .background:before {
	opacity: 0.7;
}

.menu-screen .menu-options-container .hover .menu-option .background:before {
	opacity: 0.7;
}

.menu-screen .menu-options-container .menu-option .sprite {
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 1s ease;
	position: absolute;
	top: 0;
	opacity: 1;
	border-radius: 15px;
}

.menu-screen .menu-options-container .menu-option:hover .sprite {
	opacity: 1;
}

.menu-screen .menu-options-container .hover .menu-option .sprite {
	opacity: 1;
}

.menu-screen .menu-options-container .menu-option .extra-details {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-around;
	width: 100%;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	z-index: 1;
	line-height: 1;
	opacity: 0;
	transition: all 1s ease;
}

.menu-screen .menu-options-container .menu-option .extra-details .detail-title {
	font-size: 60px;
}

.menu-screen .menu-options-container .menu-option .extra-details .detail-value {
	font-size: 4vw;
	display: flex;
	align-items: baseline;
}

.menu-screen .menu-options-container .menu-option:hover .extra-details {
	opacity: 1;
}

.menu-screen .menu-options-container .hover .menu-option .extra-details {
	opacity: 1;
}

:not(.timeout-bar).slide-enter {
	opacity: 0;
	transform: scale(0.97) translateY(5px);
	z-index: 1;
}

:not(.timeout-bar).slide-enter.slide-enter-active {
	opacity: 1;
	transform: scale(1) translateY(0);
	transition: opacity 0.5s linear 0.1s, transform 0.5s linear 0.1s;
}

:not(.timeout-bar).slide-exit {
	opacity: 1;
	transform: scale(1) translateY(0);
}

:not(.timeout-bar).slide-exit.slide-exit-active {
	opacity: 0;
	transform: scale(0.97) translateY(5px);
	transition: opacity 0.5s linear, transform 0.5s linear;
}

:not(.timeout-bar).slide-exit-done {
	opacity: 0;
}

.screen .main-title {
	position: absolute;
	top: 30px;
	left: 50px;
	font-size: 100px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.screen .screen-background {
	height: 100%;
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right;
	opacity: 0.3;
	z-index: -1;
	position: absolute;
	top: 0;
}

.workout-loading .loader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1001;
}

.workout-loading .loader:after {
	content: "";
	position: absolute;
	border: 5px solid rgba(243, 245, 247, 0.1);
	border-top: 5px solid #ffff00;
	border-radius: 50%;
	height: 50px;
	animation: spin 2s linear infinite;
	width: 50px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 0 0;
	z-index: +1;
}

.assessment-introduction {
	background-size: cover;
	background-repeat: no-repeat;
}

.assessment-introduction:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(2px);
	background-color: rgba(15, 16, 22, 0.85);
}

.assessment-introduction .assessment-coach {
	position: absolute;
	top: 80px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 28px;
}

.assessment-introduction .assessment-coach img {
	border-radius: 50%;
	width: 52px;
	height: 52px;
	min-width: 52px;
	min-height: 52px;
	margin-right: 16px;
}

.assessment-introduction .assessment-name {
	position: absolute;
	top: 170px;
	left: 50%;
	transform: translateX(-50%);
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 72px;
}

.assessment-introduction .assessment-categories {
	position: absolute;
	display: flex;
	align-items: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.assessment-introduction .assessment-categories .category {
	position: relative;
	width: 256px;
	height: 375px;
	background-size: 105%;
	background-position: 0 -5px;
	border-radius: 15px;
	margin: 0 11px;
}

.assessment-introduction .assessment-categories .category .category-name {
	position: absolute;
	bottom: 25px;
	left: 20px;
	right: 20px;
	text-align: left;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
	font-size: 43px;
}

.assessment-introduction .assessment-categories .category .category-index {
	position: absolute;
	left: 20px;
	bottom: -105px;
	-webkit-text-stroke: 2px #fff;
	font-size: 133px;
	color: transparent;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.workout.exercise {
	background-color: #d8d7d8;
}

.workout.exercise:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 20%;
	opacity: 0.5;
	background-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.5) 50%,
		rgba(0, 0, 0, 1) 100%
	);
	z-index: 1;
}

.workout.exercise.intermediate:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10%;
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 10%,
		rgba(0, 0, 0, 0) 10%,
		#000000 150%
	);
	z-index: 1;
}

.workout.exercise .screen-background {
	height: 100%;
	width: 100%;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	z-index: -1;
	position: absolute;
	top: 0;
	opacity: 0.1;
	filter: blur(10px);
}

.workout.exercise .instructions-title {
	position: absolute;
	top: 50px;
	right: 50px;
	font-size: 50px;
	color: #fff;
	z-index: +1;
}

.workout.exercise .title-container {
	position: absolute;
	top: 50px;
	left: 42px;
	z-index: +1;
	text-align: left;
	line-height: 1;
}

.workout.exercise .title-container .next-exercise-title {
	font-size: 47px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	opacity: 0.7;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	text-transform: uppercase;
}

.workout.exercise .title-container .exercise-name {
	font-size: 64.9px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.workout.exercise .intermediate-title-container {
	position: absolute;
	top: 20px;
	left: 20px;
	display: flex;
	align-items: center;
	text-align: left;
	line-height: 1;
	z-index: +1;
	font-size: 5vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	color: #ffff00;
	background-color: rgba(0, 0, 0, 0.5);
	height: 20vh;
	padding: 0 6vh;
	border-radius: 20vh;
	z-index: 1001;
}

.workout.exercise .intermediate-title-container .duration {
	font-size: 12vh;
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;
	margin-right: 10px;
}

.workout.exercise .exercise-details {
	position: absolute;
	top: 50%;
	transform: translate(150%, -50%);
	right: 20px;
	text-align: center;
	font-size: 5vh;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 100px;
	line-height: 0.9;
	width: 160px;
	padding: 35px 0;
}

.workout.exercise .exercise-details .detail {
	position: relative;
	opacity: 0;
	height: 110px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.workout.exercise .exercise-details .detail .title {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 18px;
}

.workout.exercise .exercise-details .detail .value {
	font-size: 43.9px;
}

.workout.exercise .exercise-details .detail svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100px;
}

.workout.exercise .exercise-details .detail svg circle {
	fill: none;
	stroke: #fff;
	stroke-width: 3px;
	r: 46px;
	stroke-dasharray: calc(50px * 2 * 3.14);
	stroke-dashoffset: calc(50px * 2 * 3.14);
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

.workout.exercise .exercise-difficulty {
	margin-bottom: 10px;
	width: 90px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.workout.exercise .exercise-difficulty .title {
	font-size: 3vh;
}

.workout.exercise .instructor-figure {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 75%;
	width: 500px;
	opacity: 0;
}

.workout.exercise .main-container {
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: -1;
	box-shadow: 0px 0px 100px rgba(255, 255, 255, 0.2);
}

.workout.exercise .exercise-video-container {
	//opacity: 0;
	height: 100vh;
	object-fit: cover;
	left: 50%;
	transform: translate(-50%, 0);
	position: relative;
}

.workout.exercise .exercise-video {
	height: 100%;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.workout.exercise .exercise-video-container.small {
	position: absolute;
	bottom: 25vh;
	right: 2vw;
	left: auto;
	transform: none;
	width: 20%;
	object-fit: contain;
	border-radius: 20px;
	overflow: hidden;
	height: auto;
	z-index: 1;
}

.workout.exercise .exercise-video-container.small .exercise-video {
	width: 100%;
	height: 100%;
}

.workout.exercise .exercise-video-container.fullscreen .exercise-video,
.workout.exercise.intermediate .exercise-video-container .exercise-video {
	width: 100%;
	height: auto;
	top: 50%;
	transform: translate(-50%, -50%);
}

.workout.exercise .camera-feed-container {
	overflow: hidden;
	width: 50%;
	position: absolute;
	top: 0;
	left: 0;
	height: 83vh;
	opacity: 0;
}

.workout.exercise.intermediate .camera-feed-container {
	opacity: 1;
}

.workout.exercise .camera-feed-container:before {
	content: "";
	background-color: #000;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.workout.exercise .camera-feed {
	height: 100%;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.workout.exercise .camera-feed-container.fullscreen {
	width: auto;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
}

.workout.exercise .camera-feed-container.fullscreen .camera-feed {
	width: 100%;
	right: 0;
}

.workout.exercise .camera-feed-container.small {
	position: absolute;
	bottom: 25vh;
	top: auto;
	right: 2vw;
	left: auto;
	transform: none;
	height: 25vh;
	width: calc(25vh * 1.33);
	border-radius: 25px;
}

.workout.exercise .camera-feed-container.small .camera-feed {
	width: 100%;
	right: 0;
}

.workout.exercise.intermediate .camera-feed-container {
	position: absolute;
	bottom: 50px;
	top: auto;
	left: auto;
	right: 50px;
	transform: none;
	height: 30vh;
	width: calc(30vh * 1.33);
	border-radius: 15px;
	z-index: +1;
}

.workout.exercise.intermediate .camera-feed-container .camera-feed {
	width: 100%;
	right: 0;
}

.workout.exercise .too-close {
	position: absolute;
	top: calc(83vh / 2);
	left: 25%;
	transform: translate(-50%, -50%);
	height: 30vh;
	width: 30vh;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	border: 8px solid #ff4130;
	opacity: 0;
}

.workout.exercise .too-close .label {
	position: relative;
	top: 30%;
	font-size: 5vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.workout.exercise .too-close .icons {
	position: relative;
	left: 50%;
	top: 40%;
	transform: translateX(-50%);
	display: flex;
	width: 60%;
	justify-content: space-around;
}

.workout.exercise .score-bar {
	position: absolute;
	top: 10%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	height: 55vh;
	width: calc(55vh * (38 / 610));
}

.workout.exercise .score-bar.side {
	transform: none;
	top: 20%;
	left: 2vw;
}

.workout.exercise .score-bar .background {
	width: 100%;
	height: 100%;
}

.workout.exercise .score-bar .indicator {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translate(-50%, 50%);
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: #fff;
}

.workout.exercise .score-bar .indicator:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.4;
}

.workout.exercise .score-bar .indicator:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 15px;
	width: 15px;
	border-radius: 50%;
}

.workout.exercise .score-bar.good .indicator:after {
	background-image: linear-gradient(
		71deg,
		#ffff00 -14%,
		#b3fe00 86%,
		#aafe00 86%
	);
}

.workout.exercise .score-bar.bad .indicator:after {
	background-image: linear-gradient(181deg, #ff814b 106%, #ff0052 -275%);
}

.workout.exercise .score-bar .bar {
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	height: calc((100% - 30px) * 0);
	width: 12px;
	border-radius: 6px;
}

.workout.exercise .score-bar.good .bar {
	background-image: linear-gradient(
		71deg,
		#ffff00 -14%,
		#b3fe00 86%,
		#aafe00 86%
	);
}

.workout.exercise .score-bar.bad .bar {
	background-image: linear-gradient(181deg, #ff814b 106%, #ff0052 -275%);
}

.workout.exercise .score-bar .score {
	position: absolute;
	bottom: 30px;
	right: -170px;
	width: 139px;
	height: 102px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 15px;
	line-height: 102px;
	font-size: 68px;
	transform: translateY(50%);
}

.workout.exercise .score-bar .score:before {
	content: "";
	position: absolute;
	left: -20px;
	top: 50%;
	transform: translate(-8px, -50%);
	border-width: 14px;
	border-style: solid;
	border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
}

.workout.exercise .guidelines-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	text-align: left;
	width: 45%;
	background-image: linear-gradient(45deg, #494949 0%, #262626 100%);
}

.workout.exercise .guidelines-container .guideline {
	position: absolute;
	top: 40%;
	transform: translateY(-50%);
	font-size: 4vw;
	color: #fff;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	margin: 5.5vh 2.5vw;
	opacity: 0;
	white-space: pre-wrap;
}

.workout.exercise .guidelines-container .guideline-index {
	color: rgba(255, 255, 255, 0.7);
	font-size: 5vh;
	left: 2.5vw;
	position: absolute;
	bottom: calc(5% + 17vh);
	opacity: 0;
}

.workout.exercise .exercise-graph {
	width: 100%;
	height: 85%;
}

.workout.exercise .exercise-graph .bar-chart {
	display: flex;
	height: 100%;
	width: 100%;
	position: relative;
	left: 0;
}

.workout.exercise .exercise-graph .bar-chart .bar {
	position: relative;
	height: 90%;
	width: 100%;
	max-width: 15px;
	transform: translateX(-10px);
}

.workout.exercise .exercise-graph .bar-chart .bar .background {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 15px;
	height: 100%;
	width: 15px;
	position: absolute;
}

.workout.exercise .exercise-graph .bar-chart .bar .fill {
	border-radius: 15px;
	width: 15px;
	height: 0;
	position: absolute;
	bottom: 0;
	transition: all 0.3s ease;
}

.workout.exercise .exercise-graph .bar-chart .bar .fill:before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	width: 100%;
	filter: blur(10px);
	background-image: inherit;
	z-index: -1;
}

.workout.exercise .exercise-graph .bar-chart .bar .score {
	position: absolute;
	bottom: 0;
	top: -60px;
	font-size: 50px;
	transform: translateX(-25%);
}

.workout.exercise .bottom-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 15%;
	opacity: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding: 20px 30px;
	display: flex;
}

.workout.exercise .bottom-container:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	height: 35vh;
	width: 100%;
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 25%,
		#1b1b1b 50%
	);
	z-index: -1;
}

.workout.exercise .bottom-container .count-goal {
	width: fit-content;
	line-height: 1;
	margin-right: 35px;
}

.workout.exercise .bottom-container .score {
	width: 350px;
	line-height: 1;
	display: flex;
}

.workout.exercise .bottom-container .title {
	font-size: 32px;
	color: #fff;
	text-align: left;
}

.workout.exercise .bottom-container .count-goal .title {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}

.workout.exercise .bottom-container .score .title {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.workout.exercise .bottom-container .value {
	font-size: 98px;
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;
	text-align: left;
}

.workout.exercise .bottom-container .count-goal .value span {
	font-size: 68px;
}

.workout.exercise .bottom-container .score .value span {
	font-size: 50px;
}

.workout.exercise .bottom-container .main-timer {
	height: 50px;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 30px;
	opacity: 0;
	margin-top: 25px;
}

.workout.exercise .bottom-container .main-timer::before {
	content: "";
	position: absolute;
	border: 2px solid #fff;
	border-radius: 30px;
	height: 50px;
	width: calc(100vw - 550px);
	left: 30px;
	padding: 5px;
	margin-top: -7.5px;
	margin-left: -7.5px;
}

.workout.exercise .main-text {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	line-height: 100vh;
	font-size: 10vw;
	z-index: 999;
	display: none;
	background-color: rgba(15, 16, 22, 0.85);
}

.workout.exercise .skeleton-2d {
	position: absolute;
	top: 30%;
	right: 25%;
	transform: translate(50%, -50%);
	height: 50vh;
	width: calc(60vh * 0.75);
	opacity: 0;
}

.workout.exercise .feedback-message {
	position: absolute;
	bottom: 20vh;
	right: 4vw;
	font-size: 4vw;
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;
	opacity: 0;
	color: #fff;
	border-radius: 5vh;
	padding-top: calc(8px + 2vw);
	padding-left: 3vw;
	padding-bottom: calc(2vw);
	padding-right: 5vw;
	max-width: 50vw;
	z-index: 999;
	text-align: left;
}

.workout.exercise .feedback-message img {
	position: absolute;
	right: 0;
	bottom: 0;
	transform: translate(40%, 40%);
	width: 6vw;
}

.workout.exercise .feedback-message:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	border-radius: 56px;
	border-style: solid;
	border-width: 8px;
	border-image-slice: 1;
	background-origin: border-box;
	background-clip: content-box, border-box;
	z-index: -1;
}

.workout.exercise .feedback-message.bad:before {
	background-image: linear-gradient(to bottom, #000000, #000000),
		linear-gradient(326deg, #ff7186 106%, #ff4130 0%);
	// border-image-source: linear-gradient(326deg, #ff7186 106%, #ff4130 0%);
	// -moz-border-image: linear-gradient(326deg, #ff7186 106%, #ff4130 0%);
	border-color: #ff7186;
}

.workout.exercise .feedback-message.good:before {
	background-image: linear-gradient(to bottom, #000000, #000000),
		linear-gradient(to top, #ffff00 117%, #b3fe00, #aafe00);
	// border-image-source: linear-gradient(to top, #ffff00 117%, #b3fe00, #aafe00);
	// -moz-border-image: linear-gradient(to top, #ffff00 117%, #b3fe00, #aafe00);
	border-color: #ffff00;
}

.workout.exercise .feedback-message.info:before {
	background-image: linear-gradient(to bottom, #000000, #000000),
		linear-gradient(198deg, #80c3f3 108%, #4a90e2 8%);
	// border-image-source: linear-gradient(198deg, #80c3f3 108%, #4a90e2 8%);
	// -moz-border-image: linear-gradient(198deg, #80c3f3 108%, #4a90e2 8%);
	border-color: #80c3f3;
}

.workout.exercise .feedback-message.warning:before {
	background-image: linear-gradient(to bottom, #000000, #000000),
		linear-gradient(21deg, #ff814b -2%, #ffe344 124%);
	// border-image-source: linear-gradient(21deg, #ff814b -2%, #ffe344 124%);
	// -moz-border-image: linear-gradient(21deg, #ff814b -2%, #ffe344 124%);
	border-color: #ffe344;
}

.Exercise .exercise-results {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	opacity: 0;
	background-size: cover;
}

.Exercise .exercise-results:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(15, 16, 22, 0.85);
	height: 100%;
	width: 100%;
	z-index: -1;
}

.Exercise .exercise-results .main-title {
	position: absolute;
	top: 9vh;
	left: 50%;
	transform: translateX(-50%);
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 30px;
	display: flex;
	align-items: center;
	text-align: left;
}

.Exercise .exercise-results .main-title .coach-image {
	height: 55px;
	width: 55px;
	border-radius: 50%;
	margin-right: 15px;
}

.Exercise .exercise-results .main-title .coach-name {
	color: rgba(255, 255, 255, 0.5);
}

.Exercise .exercise-results .exercise-name {
	position: absolute;
	top: 20vh;
	left: 50%;
	transform: translateX(-50%);
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 5vw;
	width: 90%;
	line-height: 1;

	@media screen and (max-width: 1300px) {
		top: 25vh;
	}
}

.Exercise .exercise-results .repetitions {
	// position: absolute;
	// top: 55%;
	// right: 23%;
	// transform: translateY(-50%);
	font-size: 5vw;
}

.Exercise .exercise-results .repetitions .repetitions-value {
	font-size: 10vh;
}

.Exercise .exercise-results .repetitions .repetitions-value span {
	font-size: 7vh;
}

.Exercise .exercise-results .repetitions .repetitions-title {
	font-size: 4vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.Exercise .exercise-results .time {
	// position: absolute;
	// top: 55%;
	// left: 23%;
	// transform: translateY(-50%);
	font-size: 5vw;
}

.Exercise .exercise-results .time .time-value {
	font-size: 10vh;
}

.Exercise .exercise-results .time .time-value span {
	font-size: 7vh;
}

.Exercise .exercise-results .time .time-title {
	font-size: 4vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.Exercise .exercise-results .workout-indicators {
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}

.Exercise .exercise-results .workout-indicators .indicator {
	margin: 0 3vw;
}

.Exercise .exercise-results .workout-indicators .indicator-value {
	font-size: 10vh;
}

.Exercise .exercise-results .workout-indicators .indicator-title {
	font-size: 4vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.Exercise .exercise-results .post-intermediate-title {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;
	font-size: 7vw;
	width: 100%;
}

.Exercise .exercise-results .next {
	position: absolute;
	top: 110%;
	left: 50%;
	transform: translateX(-50%);
	height: 50vh;
	width: calc(50vh * 3);
	display: flex;
}

.Exercise .exercise-results .next .title {
	position: absolute;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 4vh;
	left: 50%;
	transform: translate(-50%, -120%);
	background-color: #000;
	border-radius: 40px;
	padding: 10px 30px;
}

.Exercise .exercise-results .next .next-intermediate {
	display: flex;
	align-items: center;
	line-height: 1;
	font-size: 5vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	background-image: linear-gradient(
		53deg,
		#ffff00 -4%,
		#b3fe00 87%,
		#aafe00 87%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	flex-basis: 50%;
	justify-content: center;
}

.Exercise
	.exercise-results
	.next
	.next-intermediate
	.next-intermediate-duration {
	font-size: 12vh;
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;
	margin-right: 10px;
}

.Exercise .exercise-results .next .next-exercise {
	position: relative;
	display: flex;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	text-align: left;
	background-color: rgba(15, 16, 22, 0.8);
	border-radius: 15px;
}

.Exercise .exercise-results .next .next-exercise .video {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	height: 100%;
	flex-basis: 50%;
	overflow: hidden;
	position: relative;
}

.Exercise .exercise-results .next .next-exercise .video video {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.Exercise .exercise-results .next .next-exercise .next-exercise-category {
	color: rgba(255, 255, 255, 0.5);
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 2.5vw;
	margin-top: 30px;
	margin-left: 40px;
}

.Exercise .exercise-results .next .next-exercise .next-exercise-name {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 4vw;
	line-height: 1.04;
	margin-top: 10px;
	margin-left: 40px;
}

.Exercise .exercise-results .next .next-exercise .next-exercise-details {
	position: absolute;
	bottom: 40px;
	left: 40px;
	display: flex;
	align-items: flex-end;
	text-align: left;
}

.Exercise
	.exercise-results
	.next
	.next-exercise
	.next-exercise-details
	.detail {
	margin-right: 3vw;
}

.Exercise
	.exercise-results
	.next
	.next-exercise
	.next-exercise-details
	.detail
	.detail-title {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 3vh;
}

.Exercise
	.exercise-results
	.next
	.next-exercise
	.next-exercise-details
	.detail
	.detail-value {
	font-size: 4vh;
}

.Exercise
	.exercise-results
	.next
	.next-exercise
	.next-exercise-details
	.detail
	.difficulty-level {
	width: 6vw;
}

.Exercise .exercise-results .details {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%);
	@include flex(space-around, center, row);
	width: 1030px;

	@media screen and (max-width: 1300px) {
		top: 37%;
	}
}
/*
.Exercise .exercise-results .RoundScore {
	height: 45vh;
	width: 45vh;

	@media screen and (max-width: 1300px) {
		width: 43vh;
		height: 43vh;
	}
}

.Exercise .exercise-results .effectiveness {
	position: absolute;
	top: 86%;
	left: 48%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-wrap: wrap;
	height: 155px;
	width: 300px;

	@media screen and (max-width: 1300px) {
		left: 48.5%;
		top: 90%;
	}

	@media screen and (max-height: $landscape-height-large) and (max-width: $landscape-width-large) {
		display: none;
	}
}*/

/*.Exercise .exercise-results .effectiveness .effective-progress {
	.effectiveness-wrapper .effectiveness-canvas {
		.progress-value {
			top: 17%;
			font-size: 35px;

			span {
				font-size: 70px;
			}
		}

		.progress-hand {
			display: none;
		}
	}
}*/


@media screen and (max-width: 1300px) {
	/*.Exercise .exercise-results .effectiveness .effective-progress {
		max-width: 170px;

		.effectiveness-wrapper .effectiveness-canvas {
			.progress-value {
				top: 18%;

				span {
					font-size: 35px;
				}
			}
		}

		.effectiveness-title {
			bottom: 0;
		}
	}*/
}

/*.Exercise
	.exercise-results
	.effective-progress
	.effectiveness-wrapper
	.effectiveness-canvas
	> svg {
	left: 0;
}*/

.Exercise .exercise-results .set-indicator {
	position: absolute;
	font-size: 3vw;
	top: 50px;
	right: 50px;
}

.workout.exercise .pause {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1001;
}

.workout.exercise .pause:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(15, 16, 22, 0.99);
	height: 100%;
	width: 100%;
	z-index: -1;
}

.workout.exercise .pause .back-button {
	position: absolute;
	top: 35px;
	left: 20px;
	display: flex;
	align-items: center;
	z-index: +1;
	height: 12px;
	cursor: pointer;
	transition: transform 0.2s ease;
}

.workout.exercise .pause .back-button:hover {
	transform: translateX(10px);
}

.workout.exercise .pause .back-button img {
	height: 100%;
	margin: 0 5px;
}

.workout.exercise .pause .title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 10vh;
}

.workout.exercise .no-activity {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50%;
	z-index: 1001;
	display: flex;
	justify-content: center;
	align-items: center;
}

.workout.exercise .no-activity:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(15, 16, 22, 0.85);
	height: 100%;
	width: 100%;
	z-index: -1;
}

.workout.exercise .no-activity .container {
	width: 500px;
}

.workout.exercise .no-activity .title {
	font-family: $font-bold;
	font-size: $text-lg;
}

.workout.exercise .no-activity .description {
	font-size: $text-sm;
	margin-top: 5px;
}

.workout.exercise .no-activity .button {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 25px;
}

.recharts-text.recharts-polar-angle-axis-tick-value {
	fill: #fff;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 27.6px;
}

.recharts-text.recharts-polar-angle-axis-tick-value .score {
	font-size: 79.8px;
	stroke: #fff;
	stroke-width: 1px;
	fill: transparent;
}

.cursor {
	position: fixed;
	height: 150px;
	width: 150px;
	background-size: cover;
	z-index: 999;
}

.check-requirments .select-camera {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.round-timer {
	position: absolute;
	top: 20px;
	right: 20px;
	height: 20vh;
	width: 20vh;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	z-index: 1;
}

.round-timer svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: rotateY(-180deg) rotateZ(-90deg);
}

.round-timer svg circle {
	stroke-dasharray: calc(45% * 3.14 * 2);
	stroke-dashoffset: 0;
	stroke-linecap: round;
	stroke-width: 5px;
	stroke: white;
	fill: none;
}

.round-timer .value {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	line-height: 100%;
	font-size: calc(100% * 4);
}

.RoundScore {
	position: relative;
	border-radius: 50%;
}

.RoundScore .value {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
	line-height: 0.8;
}

.RoundScore svg {
	width: 100%;
	height: 100%;
	transform: rotateY(-180deg) rotateZ(-90deg);
}

.RoundScore svg .radial-bar {
	stroke-dasharray: calc(43% * 3.14 * 2);
	stroke-dashoffset: 0;
	stroke-linecap: round;
	stroke-width: 4%;
	stroke: white;
	fill: none;
}

.RoundScore svg .indicator {
	fill: #fff;
}

.gaussian-graph {
	height: 30%;
	width: 90%;
	position: absolute;
	bottom: 25%;
	left: 50%;
	transform: translateX(-50%);
}

.gaussian-graph .score {
	position: absolute;
	transform: translateX(-50%);
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 100px;
	color: #ffff00;
}

.gaussian-graph .bars-container {
	display: flex;
	justify-content: space-evenly;
	align-items: baseline;
	height: 100%;
}

.gaussian-graph .bar {
	position: relative;
	height: 100%;
	width: 0.5%;
	border-radius: 100px;
	background-image: linear-gradient(to top, rgba(255, 255, 255, 0.5), #fff);
}

.gaussian-graph .bar.selected {
	background-image: linear-gradient(to top, #ffff00 117%, #b3fe00, #aafe00);
	width: 0.8%;
}

.gaussian-graph .bar.selected:before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	width: 100%;
	filter: blur(10px);
	background-image: linear-gradient(to top, #ffff00 117%, #b3fe00, #aafe00);
}

.select-layout {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: left;
}

.select-layout .title {
	font-size: 5vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.select-layout .layouts-list {
	display: flex;
	height: 30vh;
	justify-content: space-evenly;
}

.select-layout .layouts-list .layout {
	position: relative;
	width: 30vh;
	height: 75%;
	background-color: #000;
	border-radius: 2vh;
	margin: 10vh 1vw;
	cursor: pointer;
	padding: 2vh;
}

.select-layout .layouts-list .layout img {
	width: 100%;
}

.select-layout .layouts-list .layout.selected {
	border: 3px solid #b3fe00;
}

.select-layout .layouts-list .layout .selected-icon {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	height: 60%;
	z-index: +1;
	width: auto;
	opacity: 0;
}

.select-layout .layouts-list .layout.selected .selected-icon {
	opacity: 1;
}

.select-layout .layouts-list .layout .label {
	position: absolute;
	width: 100%;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	font-size: 4vh;
	left: 50%;
	transform: translateX(-50%);
}

.select-layout .button {
	margin-top: 10vh;
	left: 50%;
	transform: translateX(-50%);
}

.select-camera .title {
	font-size: 5vh;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	color: #fff;
}

.select-camera .cameras-list {
	display: flex;
	height: 30vh;
	justify-content: space-evenly;
}

.select-camera .cameras-list .camera {
	position: relative;
	width: 30vh;
	height: 75%;
	background-color: #000;
	border-radius: 2vh;
	margin: 10vh 1vw;
	cursor: pointer;
	overflow: hidden;
}

.select-camera .cameras-list .camera.selected {
	border: 3px solid #b3fe00;
}

.select-camera .cameras-list .camera.selected .selected-icon {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	height: 60%;
	z-index: +1;
}

.select-camera .cameras-list .camera .label {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	line-height: 3;
}

.select-camera .cameras-list .camera .camera-icon {
	height: 20%;
	top: calc(50% - 15px);
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}

.select-camera .cameras-list .camera .camera-feed {
	width: 300px;
	height: 200px;
}

.select-camera .button {
	margin-top: 10vh;
	left: 50%;
	transform: translateX(-50%);
}

.select-camera-modal .select-camera {
	margin: 30px;
}

.select-camera-modal .select-camera .title {
	color: $primary-text-color;
}

.settings .screen-title {
	position: absolute;
	left: 50%;
	top: 5vh;
	transform: translateX(-50%);
	font-size: 5vh;
}

.settings .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.settings .back-button {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 10vh;
}

.settings .select-layout {
	text-align: center;
	z-index: +1;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 4vh;
	border-radius: 1vh;
	backdrop-filter: blur(10px);
}

.debug-h3 {
	@media screen and (max-width: $mobile-large) {
		position: relative;
		top: 260px;
	}
}

.debug-canvas {
	transform: scaleX(-1);
	border-radius: 12px;

	@media screen and (max-width: $mobile-large) {
		transform: scaleX(-1) scale(0.5);
	}
}

.debug {
	color: white;
}

.input {
	position: relative;
	margin: 20px 0;

	.input-label {
		position: absolute;
		top: 50%;
		left: 13px;
		transform: translateY(-50%);
		color: #808d9b;
		text-align: left;
		font-size: 17px;
		pointer-events: none;
		transition: all 0.2s ease;
	}

	input[type="text"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="email"],
	textarea {
		background: #fff;
		border: solid 1px #cbd4dc;
		padding: 0 0.677vw;
		padding-top: 14px;
		padding-bottom: 0;
		height: 1.354vw;
		font-size: 0.833vw;
		border-radius: 5px;
		outline: none;
		color: #808d9b;
		width: -webkit-fill-available;
		width: -moz-available;
		font-family: inherit;
	}

	textarea {
		height: auto;
		resize: none;
		padding-top: 20px;
	}

	&.no-label {
		input[type="text"],
		input[type="email"],
		input[type="number"],
		input[type="password"],
		textarea {
			padding: 7px 13px;
		}
	}

	input[type="text"]:focus,
	input[type="email"]:focus,
	input[type="number"]:focus,
	input[type="password"]:focus,
	textarea:focus {
		border: solid 1px #808d9b;
	}

	& > input:focus + .input-label,
	& > input:valid + .input-label {
		transform: translateY(-100%);
		font-size: 12px;
	}

	& > textarea:focus + .input-label,
	& > textarea:valid + .input-label {
		top: 15px;
		font-size: 12px;
	}

	.help-text {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(-50%, -45%);
		z-index: +1;
		color: #808d9b;
		pointer-events: all;
	}

	.help-text:hover {
		color: $primary-text-color;
	}

	.help-text svg {
		font-size: 20px;
	}

	.previous-value {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translate(100%, -50%);
		z-index: +1;
		color: red;
		cursor: pointer;
		border-radius: 50%;
		padding: 10px;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
}

select {
	width: -webkit-fill-available;
	width: -moz-available;
	background-color: transparent;
	border: solid 1px #cbd4dc;
	font-size: inherit;
	font-family: inherit;
	color: #808d9b;
	border-radius: 5px;
	outline: none;
	padding: 10px;
}

select option {
	color: $primary-text-color;
}

textarea {
	width: auto;
	background-color: transparent;
	border: solid 1px #cbd4dc;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	border-radius: 5px;
	outline: none;
}

.cookies-disclaimer {
	position: absolute;
	bottom: 0;
	width: 80%;
	background-color: #fff;
	color: $primary-text-color;
	display: flex;
	align-items: center;
	padding: 0 10%;
}

.mobile .cookies-disclaimer {
	display: block;
	width: 95%;
	padding: 0 2.5%;
}

.cookies-disclaimer p {
	font-size: 1vw;
	margin: 3vh 0;
	text-align: left;
}

.mobile .cookies-disclaimer p {
	font-size: 3vw;
	margin: 0;
	margin-top: 2vh;
}

.cookies-disclaimer .button2 {
	color: $primary-text-color;
	border: 1px solid $primary-text-color;
}

.mobile .cookies-disclaimer .button2 {
	padding: 10px 5px;
}

.default-theme:not(.mobile).login .button {
	top: 0;
}

.regular-login {
	h2 {
		font-size: 18px;
		font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
		text-align: center;
		margin: 0;
		color: #000;

		@media screen and (max-width: $mobile-large) {
			color: #fff;
		}
	}

	.title {
		margin-bottom: 20px;
	}
}

.regular-login .activate-account {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
}

.regular-login .activate-account .description {
	padding: 0;
	margin: 0;
	font-size: 20px;
}

.difficulty-level {
	height: 100%;
	width: 100%;
	display: flex;
}

.difficulty-level img {
	width: 100%;
}

.difficulty-level.invert img {
	filter: invert(100%);
}

.difficulty-level.editable img {
	opacity: 0;
}

.difficulty-level.editable:hover img {
	opacity: 0.5;
	cursor: pointer;
}

.checkbox {
	display: flex;
	align-items: center;
}

.checkbox .label {
	margin-left: 10px;
}

.checkbox .check {
	height: 15px;
	width: 15px;
	padding: 3px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 5px;
	cursor: pointer;
}

.checkbox.checked .check path {
	fill: #ffff00;
}

.checkbox.dark {
	.check {
		border: 1px solid rgba(0, 0, 0, 0.5);

		path {
			fill: #000;
		}
	}
}

.checkbox.disabled {
	opacity: 0.5;

	.check {
		cursor: default;
	}
}

.instructions .title {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 70%;
	font-size: 10vh;
}

.instructions iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: calc(60vw * 9 / 16);
	width: 60vw;
	padding: 5vh 0;
}

.instructions .button {
	position: absolute;
	bottom: 8vh;
	left: 50%;
	transform: translateX(-50%);
}

.tips {
	position: absolute;
	bottom: 5vh;
	width: 100%;
}

.tips .tip {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	font-size: 2vh;
	width: 100%;
	opacity: 0;
}

.tips .tip span {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.tips .pagination {
	display: flex;
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
}

.tips .pagination div {
	height: 10px;
	width: 10px;
	background-color: #fff;
	border-radius: 50%;
	margin: 0 5px;
	cursor: pointer;
}

.error-screen .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 5%;
	padding: 5vh;
	text-align: center;
}

.error-screen .title {
	font-size: 3vh;
	margin-top: 1vh;
}

.error-screen .description {
	margin-top: 2vh;
}

.error-screen svg {
	color: #ffff00;
}

.regression-test .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 5%;
	padding: 5vh;
	text-align: center;
	color: $primary-text-color;
}

.regression-test .title {
	font-size: 3vh;
	margin-bottom: 5vh;
}

.regression-test .button {
	margin-top: 2vh;
	width: auto;
}

.countdown-timer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 30vh;
	width: 30vh;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	opacity: 0;
	z-index: 3;
}

/*
@keyframes countdownBounce {
		0% {
      transform: translate(-50%, -50%) scale(1);
		}
    50% {
      transform: translate(-50%, -50%) scale(1.1);
    }
		100% {
			transform: translate(-50%, -50%) scale(1);
		}
}
*/

.countdown-timer .value {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	line-height: 100%;
	font-size: 10vh;
}

.countdown-timer .animation {
	opacity: 0;
}

.countdown-timer.counting .animation {
	transition: opacity 0.3 ease;
	opacity: 1;
}

.countdown-timer .animation img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
	width: 100%;
}

.clock-animation img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
	width: 100%;
}

.terms-of-use {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
}

.mobile .terms-of-use {
	position: fixed;
}

.terms-of-use:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
}

.terms-of-use .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 2vh;
	padding: 5vh;
	text-align: center;
	color: $primary-text-color;
	height: 70vh;
	width: 50vw;
	overflow: hidden;

	@media screen and (max-width: $md) {
		height: 95vh;
		width: 95vw;
	}
}

.mobile .terms-of-use .container {
	height: 80vh;
	width: 75vw;
}

.mobile .terms-of-use .container .content {
	height: 85%;
}

.terms-of-use .container .content {
	text-align: left;
	height: 78%;
	max-height: calc(100% - 80px);
	overflow: auto;
	line-height: 1.5;
}

.terms-of-use.standalone .container .content {
	height: 100%;
}

.terms-of-use .container .title {
	text-align: center;
	font-size: 3vh;
	margin-bottom: 2vh;
}

.terms-of-use .container li {
	margin: 1vh 0;
}

.terms-of-use .container .content a {
	color: #000;
	text-decoration: underline;
}

.terms-of-use .container .content b {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.terms-of-use .container .button {
	left: 50%;
	transform: translate(-50%, 25%);
	width: fit-content;
}

.mobile .terms-of-use .container .button {
	font-size: 16px;
	left: 0;
	transform: none;
	margin-top: 15px;
}

.terms-of-use .container .content::-webkit-scrollbar-track {
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.1);
}

.terms-of-use .container .content::-webkit-scrollbar {
	width: 8px;
	background-color: rgba(0, 0, 0, 0.1);
}

.terms-of-use .container .content::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #000;
}

@media only screen and (max-height: $landscape-height-large) and (max-width: $landscape-width-large) and (min-width: 550px) and (orientation: landscape) {
	.mobile .terms-of-use .container {
		height: 90vh;
		padding: 5vw;
		box-sizing: border-box;
	}

	.mobile .terms-of-use .container .title {
		margin-top: -15px;
		font-size: 3vw;
	}

	.mobile .terms-of-use .container .content {
		border-radius: 8px;
		height: 70%;
		max-height: 70%;
		background-color: rgb(245, 243, 243);
	}

	.mobile .terms-of-use .container .button {
		font-size: 14px;
	}
}

.privacy-policy .terms-of-use:before {
	background-color: rgba(0, 0, 0, 0.5);
}

.default-theme .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 2vh;
	padding: 43px;
	text-align: center;
	color: $primary-text-color;
	z-index: +1;
}

.default-theme.mobile .container {
	padding: 3vh;
	width: 75vw;
	height: 70vh;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;

	@media screen and (max-width: $mobile-medium) {
		height: 72vh;
	}
}

.camera-permission.mobile .button {
	margin: auto;
}

.default-theme .title {
	font-size: 3vh;
	margin-top: 1vh;
}

.default-theme:not(.mobile) .button {
	left: 50%;
	transform: translateX(-50%);
	top: 2vh;
}

.default-theme .logo {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 10%;
	z-index: 1;
}

.default-theme.mobile .logo {
	top: 25px;
	width: 50%;
}

.default-theme .background {
	object-fit: cover;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
	width: 100%;
}

.default-theme.mobile .background {
	position: fixed;
}

.default-theme .description {
	color: #808d9b;
	font-size: 2vh;
	margin-top: 1vh;
	margin-bottom: 4vh;
	padding: 0 5vw;
}

.default-theme .error {
	color: red;
	margin: 10px 8px;
	text-align: center;
	width: 100%;
}

.default-theme .success {
	color: green;
	margin: 10px 8px;
	text-align: center;
	width: 100%;
}

.default-theme .back-button {
	position: absolute;
	left: 50%;
	bottom: 2vh;
	transform: translateX(-50%);
}

.default-theme .link {
	color: $primary-text-color;
	cursor: pointer;
	text-decoration: underline;
}

.exercises-editor .exercises-categories .category .exercises-list .exercise {
	background-color: rgba(255, 255, 255, 0.1);
	padding: 20px;
	margin: 20px 0;
	border-radius: 10px;
	position: relative;
}

.exercises-editor .exercises-list .exercise .title {
	display: flex;
	align-items: center;
	font-size: 20px;
}

.exercises-editor .exercises-list .exercise .category {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	margin-right: 5px;
}

.exercises-editor .exercises-list .exercise .editing-action {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	margin-left: 10px;
	padding-left: 5px;
	border-left: 1px solid #fff;
}

.exercises-editor .exercises-list .exercise .edit-exercise {
	position: absolute;
	right: 20px;
	cursor: pointer;
	border-radius: 50%;
	padding: 10px;
}

.exercises-editor .exercises-list .exercise .edit-exercise:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.exercises-editor .exercises-list .exercise .more {
	margin: 50px;
	height: 150px;
	display: grid;
	grid-template-columns: 30% 70%;
	text-align: left;
}

.exercises-editor .exercises-list .exercise .more .guidelines {
	margin-left: 70px;
}

.exercises-editor .exercises-list .exercise .more .subtitle {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
}

.exercises-editor .exercises-list .exercise .more .guidelines .add-guideline {
	cursor: pointer;
	margin-left: 15px;
}

.exercises-editor
	.exercises-list
	.exercise
	.more
	.guidelines
	.remove-guideline {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	cursor: pointer;
	margin-left: 10px;
}

.exercises-editor .exercises-list .exercise .more .property {
	margin: 30px 0;
}

.exercises-editor .exercises-list .exercise .media {
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
	width: 850px;
}

.exercises-editor .exercises-list .exercise .media .videos {
	display: flex;
	justify-content: center;
}

.exercises-editor .exercises-list .exercise .media .videos > div {
	margin: 0 20px;
}

.exercises-editor .exercises-list .exercise .media .subtitle {
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	margin-bottom: 5px;
}

.exercises-editor .exercises-list .exercise .media .video {
	width: 30vw;
	height: calc(30vw * 0.5625);
}

.exercises-editor .exercises-list .exercise .media .preview {
	left: 50%;
	transform: translateX(-50%);
}

.exercises-editor .exercises-list .exercise .media .preview.disabled {
	opacity: 0.5;
	cursor: default;
}

.exercises-editor .exercises-list .exercise .media .preview.disabled:hover {
	background-color: transparent;
}

.exercises-editor .exercises-list .exercise .buttons {
	position: absolute;
	bottom: 0;
	right: 20px;
	display: flex;
	align-items: center;
}

.exercises-editor .exercises-list .exercise .buttons .button4 {
	margin: 0 20px;
}

.exercises-editor .exercises-list .exercise .buttons .button5 {
	color: #fff;
}

.exercises-editor .exercises-list .exercise .waiting-for-approval {
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 20px;
	padding: 5px 10px;
	font-size: 20px;
	width: fit-content;
	margin-left: 20px;
}

.ExercisePreview {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
	color: #fff;

	.actions {
		position: fixed;
		top: 50px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9999;
		display: flex;
		grid-gap: 20px;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 20px;
		border-radius: 50px;
	}
}

.exercises-editor .exercise-edit {
	position: fixed;
	color: $primary-text-color;
	z-index: +1;
}

.exercises-editor .exercise-edit:before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
}

.exercises-editor .exercise-edit .container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	height: 670px;
	width: max-content;
	border-radius: 20px;
	z-index: +1;
}

.exercises-editor .exercise-edit .container .title {
	width: 400px;
	text-align: left;
}

.exercises-editor .exercise-edit .container .buttons {
	position: absolute;
	bottom: 0;
	right: 20px;
	display: flex;
	align-items: center;
}

.exercises-editor .exercise-edit .container .buttons .button4 {
	margin: 0 20px;
}

.exercises-editor .exercise-edit .container .fields {
	padding: 50px;
}

.exercises-editor .exercise-edit .container .fields .video {
	width: 400px;
	height: 225px;
	color: #fff;
}

.coaches-editor .add-coach {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 20px;
	position: absolute;
	right: 50px;
	top: 130px;
	border-bottom: 1px solid #fff;
	cursor: pointer;
}

.coaches-editor .coaches-list {
	margin: 30px;
	top: 70px;
	position: relative;
	display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 30px;
}

.coaches-editor .coaches-list .coach {
	background-color: rgba(255, 255, 255, 0.1);
	padding: 20px;
	margin: 20px 0;
	border-radius: 10px;
	position: relative;
}

.coaches-editor .coaches-list .coach .coach-details {
	display: flex;
	text-align: left;
	align-items: center;
}

.coaches-editor .coaches-list .coach .coach-details .coach-image {
	height: 150px;
	width: 150px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 30px;
}

.coaches-editor .coaches-list .coach .coach-details .detail {
	margin: 15px 0;
}

.coaches-editor .coaches-list .coach .coach-details .detail-title {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
}

.coaches-editor .coach-edit {
	position: fixed;
	color: $primary-text-color;
	z-index: +1;
}

.coaches-editor .coach-edit:before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
}

.coaches-editor .coach-edit .container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	width: max-content;
	height: max-content;
	border-radius: 20px;
	z-index: +1;
	padding: 20px 50px;
}

.coaches-editor .coach-edit .container select {
	background: #fff;
	border: solid 1px #cbd4dc;
	padding: 10px 15px;
	font-size: 1em;
	border-radius: 5px;
	outline: none;
	color: #808d9b;
	width: -webkit-fill-available;
	width: -moz-available;
}

.coaches-editor .coach-edit .container .users-list {
	text-align: left;
	height: 300px;
	overflow-y: auto;
}

.coaches-editor .coach-edit .container .users-list .user {
	padding: 10px 20px;
	cursor: pointer;
}

.coaches-editor .coach-edit .container .users-list .user:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.coaches-editor .coach-edit .container .users-list .user.selected {
	background-color: rgba(0, 0, 0, 0.2);
}

.coaches-editor .coach-edit .container .buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.payments .add-user {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 20px;
	position: absolute;
	right: 50px;
	top: 130px;
	border-bottom: 1px solid #fff;
	cursor: pointer;
}

.payments .payments-list {
	margin: 30px;
	top: 70px;
	position: relative;
	display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 30px;
}

.payments .payments-list .user {
	background-color: rgba(255, 255, 255, 0.1);
	padding: 20px;
	margin: 20px 0;
	border-radius: 10px;
	position: relative;
}

.payments .payments-list .user .user-details {
	display: flex;
	text-align: left;
	align-items: center;
}

.payments .payments-list .user .user-details .user-image {
	height: 150px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 30px;
}

.payments .payments-list .user .user-details .detail {
	margin: 15px 0;
}

.payments .payments-list .user .user-details .detail-title {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
}

.payments .user-edit {
	position: fixed;
	color: $primary-text-color;
	z-index: +1;
}

.payments .user-edit:before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
}

.payments .user-edit .container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	width: max-content;
	height: max-content;
	border-radius: 20px;
	z-index: +1;
	padding: 20px 50px;
}

.payments .user-edit .container select {
	background: #fff;
	border: solid 1px #cbd4dc;
	padding: 10px 15px;
	font-size: 1em;
	border-radius: 5px;
	outline: none;
	color: #808d9b;
	width: -webkit-fill-available;
	width: -moz-available;
}

.payments .user-edit .container .users-list {
	text-align: left;
	height: 300px;
	overflow-y: auto;
}

.payments .user-edit .container .users-list .user {
	padding: 10px 20px;
	cursor: pointer;
}

.payments .user-edit .container .users-list .user:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.payments .user-edit .container .users-list .user.selected {
	background-color: rgba(0, 0, 0, 0.2);
}

.payments .user-edit .container .buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.workout-edit-modal .section {
	position: relative;
	margin-top: 90px;
	width: max-content;
}

.workout-edit-modal .section .section-title {
	color: $primary-text-color;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 25px;
	position: absolute;
	left: 0;
	top: -50px;
}

.workout-edit-modal .description {
	width: 500px;
}

.workout-edit-modal .soundtrack {
	display: flex;
	align-items: center;

	audio {
		margin-left: 30px;
	}
}

.workout-edit-modal .attributes .subtitle {
	color: $primary-text-color;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 25px;
	position: absolute;
	left: 0;
	top: -50px;
}

.workout-edit-modal .attributes {
	width: 500px;
}

.workout-edit-modal .attributes select::-webkit-scrollbar-track {
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.1);
}

.workout-edit-modal .attributes select::-webkit-scrollbar {
	width: 8px;
	background-color: rgba(0, 0, 0, 0.1);
}

.workout-edit-modal .attributes select::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #000;
}

.workout-edit-modal .attributes .select-caption {
	color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
	margin-top: 10px;
}

.workout-edit-modal .exercises {
	margin-top: 150px;
	max-width: 1000px;
	width: 70vw;
}

.workout-edit-modal .exercises .total-duration {
	color: $primary-text-color;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 20px;
	position: absolute;
	top: -50px;
	right: 0;
}

.workout-edit-modal .exercises .labels {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	display: flex;
	text-align: left;
	margin: 0 0 10px 80px;
}

.workout-edit-modal .exercises .exercise {
	position: relative;
	display: flex;
	align-items: center;
	padding: 20px;
	text-align: left;
}

.workout-edit-modal .exercises .exercise select {
	width: 200px;
}

.workout-edit-modal .exercises .exercise.error {
	background-color: rgba(255, 0, 0, 0.3);
}

.workout-edit-modal .exercises .exercise:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.workout-edit-modal .exercises .exercise .label {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
}

.workout-edit-modal .exercises .exercise .move {
	cursor: move;
	margin-right: 40px;
}

.workout-edit-modal .exercises .exercise .remove {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px;
}

.workout-edit-modal .exercises .exercise .remove:hover:after {
	content: "Remove";
	position: absolute;
	background-color: #0f1016;
	padding: 10px;
	border-radius: 5px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	color: #fff;
	width: max-content;
	top: 130%;
	left: 50%;
	transform: translateX(-50%);
}

.workout-edit-modal .exercises .exercise .duplicate {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 50px;
}

.workout-edit-modal .exercises .exercise .duplicate:hover:after {
	content: "Duplicate";
	position: absolute;
	background-color: #0f1016;
	padding: 10px;
	border-radius: 5px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	color: #fff;
	width: max-content;
	top: 130%;
	left: 50%;
	transform: translateX(-50%);
}

.workout-edit-modal .exercises .exercise .difficulty-level {
	width: 50%;
}

.workout-edit-modal .exercises .exercise .difficulty-level img {
	filter: invert(100%);
}

.workout-edit-modal .exercises .add-exercise {
	position: relative;
	padding: 20px;
	padding-left: 85px;
	text-align: left;
	cursor: pointer;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
}

.workout-edit-modal .exercises .add-exercise:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.workout-edit-modal .buttons {
	position: absolute;
	bottom: 0;
	right: 20px;
	display: flex;
	align-items: center;
}

.workout-edit-modal .buttons .button4 {
	margin: 0 20px;
}

.workout-edit-modal .new-workout {
	text-align: left;
	margin: 50px;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 50px;
	justify-content: flex-start;
}

.workout-edit-modal .new-workout .label {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	margin-bottom: 5px;
}

.workout-edit-modal .new-workout input,
.workout-edit-modal .new-workout textarea {
	background: #fff;
	border: solid 1px #cbd4dc;
	padding: 10px 15px;
	font-size: 1em;
	border-radius: 5px;
	outline: none;
	color: #808d9b;
	width: -webkit-fill-available;
	width: -moz-available;
}

.workout-edit-modal .new-workout input:focus,
.workout-edit-modal .new-workout textarea:focus {
	border: solid 1px #808d9b;
}

.workout-edit-modal .new-workout select {
	background: #fff;
	border: solid 1px #cbd4dc;
	padding: 10px 15px;
	font-size: 1em;
	border-radius: 5px;
	outline: none;
	color: #808d9b;
	width: -webkit-fill-available;
	width: -moz-available;
}

.workout-edit-modal .new-workout .image {
	width: 20vw;
	height: calc(20vw * 0.57);
}

.coach-image-editor {
	display: flex;
	justify-content: center;
}

.coach-image-editor .image-container {
	overflow: hidden;
	position: relative;
	height: 300px;
	width: 100%;
}

.coach-image-editor canvas {
	cursor: move;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.coach-image-editor .overlay {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid rgba(255, 255, 255, 0.5);
	box-sizing: border-box;
	box-shadow: 0 0 0 200px;
	color: rgba(0, 0, 0, 0.3);
	overflow: hidden;
	border-radius: 50%;
	height: 200px;
	width: 200px;
	pointer-events: none;
}

.coach-image-editor .zoom {
	position: absolute;
	left: 50%;
	bottom: 70px;
	transform: translateX(-50%);
	width: 80%;
}

.coach-image-editor .done-button {
	position: absolute;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	text-decoration: underline;
	cursor: pointer;
	font-size: 20px;
}

.admin-dashboard .kemtai-workouts-editor {
	padding: 50px;
}

.admin-dashboard .kemtai-workouts-editor .workout-list-container {
	padding-top: 50px;
}

.editable-label {
	display: inline;
}

.editable-label input,
.editable-label select,
.editable-label textarea {
	width: auto;
	background-color: transparent;
	border: solid 1px #cbd4dc;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	border-radius: 5px;
	outline: none;
}

.editable-label select option {
	color: $primary-text-color;
}

.editable-label.error {
	color: red;
}

.editable-image {
	position: relative;

	.previous-value {
		position: absolute;
		right: -10px;
		top: 50%;
		transform: translate(100%, -50%);
		z-index: +1;
		color: red;
		cursor: pointer;
		border-radius: 50%;
		padding: 10px;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
}

.editable-image img {
	width: inherit;
	height: inherit;
	min-width: inherit;
	min-height: inherit;
}

.editable-image.editing:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
}

.editable-image .change-image {
	position: absolute;
	bottom: 5px;
	right: 5px;
	z-index: +1;
	height: 45px;
	width: 45px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editable-image .change-image svg {
	color: #fff;
}

.editable-image .change-image:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.editable-image .change-image input {
	display: none;
}

.editable-image .placeholder {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.editable-video {
	position: relative;
}

.editable-video video {
	width: inherit;
	height: inherit;
}

.editable-video .change-video {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: +1;
	height: 45px;
	width: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
}

.editable-video .change-video:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.editable-video .change-video:hover:after {
	content: "Upload video";
	position: absolute;
	background-color: #0f1016;
	padding: 10px;
	border-radius: 5px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	color: #fff;
	width: max-content;
	left: 50%;
	top: 0;
	transform: translate(-50%, -110%);
}

.editable-video .change-video input {
	display: none;
}

.editable-video .placeholder {
	width: inherit;
	height: inherit;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.introduction-modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1000;
	opacity: 1;
}

.introduction-modal:before {
	content: "";
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.introduction-modal.test:before {
	background-color: rgba(15, 16, 22, 0.87);
}

.introduction-modal.test.D:before {
	background-color: rgba(15, 16, 22, 0.5);
}

.introduction-modal.test .logo {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 10%;
	z-index: 1;
}

.introduction-modal .container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 200px);
	background-color: #fff;
	border-radius: 25px;
	text-align: center;
	width: 25vw;
	color: $primary-text-color;
	height: 505px;
	width: 846px;
	overflow: visible;
	opacity: 0;
}

.introduction-modal .container .close-button {
	position: absolute;
	top: 0;
	right: 30px;
	transform: translateY(-50%);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
	background-color: #fff;
	z-index: +1;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	cursor: pointer;
}

.introduction-modal .container .close-button svg {
	top: 50%;
	left: 50%;
	position: relative;
	transform: translate(-70%, -50%);
}

.introduction-modal.test .link {
	text-decoration: underline;
	cursor: pointer;
}

.introduction-modal.test .container .timeout-bar {
	position: absolute;
	top: 30px;
	left: 20px;
	height: 5px;
	width: 45%;
	background-color: rgba(27, 31, 34, 0.2);
	margin: 0 5px;
	border-radius: 5px;
	z-index: 0;
}

.introduction-modal.test .container .timeout-bar .bar {
	position: absolute;
	left: 0;
	width: 100%;
	background-color: #1b1f22;
	z-index: +1;
}

.introduction-modal.test .checkbox svg {
	border: 1px solid #000;
}

.introduction-modal.test .checkbox svg path {
	fill: #000;
}

.introduction-modal .container .indicators {
	position: absolute;
	top: 30px;
	left: 20px;
	display: flex;
	width: 100%;
}

.introduction-modal .container .indicators .indicator {
	height: 5px;
	width: 10%;
	background-color: #1b1f22;
	opacity: 0.2;
	margin: 0 5px;
	border-radius: 5px;
}

.introduction-modal .container .indicators .indicator.active {
	opacity: 1;
}

.introduction-modal .container .page {
	display: flex;
	height: 100%;
	overflow: hidden;
	border-radius: 25px;
}

.introduction-modal .container .right-side {
	flex-basis: 50%;
	overflow: hidden;
	position: relative;
}

.introduction-modal .container .left-side {
	position: relative;
	flex-basis: calc(50% - 60px);
	text-align: left;
	padding: 10vh 30px;
}

.introduction-modal .container .left-side .buttons {
	position: absolute;
	bottom: 4vh;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.introduction-modal .container .right-side video {
	height: 105%;
	top: -5px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 25px;
	outline: 0;
	background-color: #fff;
}

.introduction-modal.test .container .right-side .video-play-button {
	height: 80px;
	width: 80px;
	position: fixed;
}

.introduction-modal .container .right-side img {
	height: 100%;
	width: 100%;
}

.introduction-modal .container .right-side .play-button1 {
	position: fixed;
	left: 50%;
	top: 53%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	height: 150px;
	width: 150px;
}

.introduction-modal.test .container .right-side .play-button1 {
	position: absolute;
	left: 50%;
	top: 53%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	height: 100px;
	width: 100px;
}

.introduction-modal .container .right-side .play-button2 {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	height: 70px;
	width: 70px;
	display: none;
}

.introduction-modal .container .left-side .title {
	font-size: 28px;
	margin-bottom: 30px;
}

.introduction-modal .container .left-side .text {
	font-family: OpenSans-Regular;
	font-size: 14px;
	color: #808d9b;
}

.introduction-modal.test .container .left-side {
	flex-basis: 50%;
	text-align: center;
	padding: 10vh 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.introduction-modal.test .container .left-side .title {
	font-size: 20px;
	margin-bottom: 15px;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	text-align: left;
}

.introduction-modal.test .container .left-side .text {
	font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #808d9b;
}

.introduction-modal.test .container .left-side .text .button {
	margin-top: 39px;
	left: 50%;
	transform: translateX(-50%);
}

.introduction-modal.test .container .left-side .buttons {
	display: flex;
	justify-content: center;
	bottom: 15px;
}

.introduction-modal.test .container .left-side .timeout-circle {
	position: relative;
	margin: auto;
	height: 44px;
	width: 44px;
	text-align: center;
	margin-bottom: 22px;
}

.introduction-modal.test
	.container
	.left-side
	.timeout-circle
	.timeout-circle-value {
	color: $primary-text-color;
	display: inline-block;
	line-height: 44px;
	font-size: 24px;
}

.introduction-modal.test .container .left-side .timeout-circle svg {
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 44px;
	transform: rotateY(-180deg) rotateZ(-90deg);
}

.introduction-modal.test .container .left-side .timeout-circle svg circle {
	stroke-dasharray: 126px;
	stroke-dashoffset: 0px;
	stroke-linecap: round;
	stroke-width: 2px;
	stroke: #1e2227;
	fill: none;
	animation: timeout-circle 15s linear forwards;
}

.introduction-modal.test .login {
	position: absolute;
	bottom: 85px;
	left: 50%;
	transform: translateX(-50%);
	font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
	color: #fff;
	font-size: 14px;
}

.introduction-modal.test .login a {
	color: #fff;
	text-decoration: underline;
}

@media screen and (max-height: 800px) {
	.introduction-modal.test .login {
		bottom: 45px;
	}
}

@media screen and (max-height: 700px) {
	.introduction-modal.test .login {
		bottom: 5px;
	}
}

.introduction-modal.test .camera-selection-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	border-radius: 25px;
	padding: 35px 0;
}

.introduction-modal.test .camera-selection-container .title {
	color: $primary-text-color;
}

@keyframes timeout-circle {
	from {
		stroke-dashoffset: 0px;
	}
	to {
		stroke-dashoffset: 126px;
	}
}

.alignment-testing {
	color: #fff;
}

.alignment-testing .user {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 50%;
}

.alignment-testing .user .title {
	text-align: center;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 20px;
}

.alignment-testing .user .camera-feed {
	height: 100%;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.alignment-testing .user .inputs {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: +1;
	width: fit-content;
}

.alignment-testing .coach {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 50%;
}

.alignment-testing .coach .title {
	text-align: center;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 20px;
}

.alignment-testing .coach .coach-video {
	height: 100%;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.alignment-testing .coach input {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: +1;
	width: fit-content;
}

.alignment-testing .bottom-container {
	position: absolute;
	bottom: 0;
	height: 50%;
	left: 0;
	width: 100%;
}

.alignment-testing .attributes {
	color: #fff;
}

.alignment-testing .attributes .attribute {
	margin: 50px;
}

.alignment-testing .attributes .attribute .label {
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 40px;
}

.alignment-testing .attributes .attribute .value {
	font-size: 50px;
}

.alignment-testing .create-alignment .video-preview {
	height: 50%;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.alignment-testing .create-alignment .choose-data {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	color: $primary-text-color;
	width: 80%;
	height: 80%;
	padding: 50px;
	border-radius: 20px;
	overflow: auto;
}

.alignment-testing .create-alignment .choose-data .title {
	text-align: center;
	font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
	font-size: 20px;
}

.alignment-testing .create-alignment .choose-data .data {
	cursor: pointer;
	margin-bottom: 20px;
	padding: 10px;
	width: 100%;
	word-break: break-all;
}

.alignment-testing .create-alignment .choose-data .data:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.recordings {
	width: 20%;
	position: absolute;
	// top: 8%;
	left: 1%;

	z-index: 1;
	/*
  animation: blink 3s;
  animation-iteration-count: 3;
  */
}

.intermediate .recordings {
	width: 20%;
	position: absolute;
	// top: 25%;
	left: 1%;
	z-index: 1;
}

.tooltip {
	position: relative;
}

.tooltip .tooltip-label {
	position: absolute;
	background-color: #0f1016;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	width: max-content;
	left: 0;
	top: 0;
	transform: translateY(-100%);
	z-index: +1;
	display: none;
}

.tooltip:hover .tooltip-label {
	display: block;
}

.circle-animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scaleX(-1);
}

.circle-animation circle {
	stroke-dashoffset: 0;
	stroke-linecap: round;
	stroke-width: 5px;
	stroke: white;
	fill: none;
}

.auto-complete-search .search-field {
	display: flex;
	align-items: center;
	// border: solid 1px rgba(255, 255, 255, 0.5);
	border-radius: 25px;
	padding: 0 10px;
	width: 206px;
	height: 42px;
	background-color: #0d0e12;
	transition: width 0.3s ease-in-out;
	// margin: 27px 300px 0 934px;
	// padding: 0 53px 0 17px;
	// opacity: 0.26;
	// border-radius: 21px;
	// border: solid 1px rgba(255, 255, 255, 0.26);
}

.auto-complete-search .search-field.extended {
	width: 381px;
}

.auto-complete-search input {
	background: transparent;
	padding: 5px 15px;
	height: 30px;
	font-size: 1em;
	outline: none;
	color: inherit;
	width: 100%;
	font-family: inherit;
	border: none;
}

.auto-complete-search .search-results {
	border-bottom-left-radius: 21px;
	border-top-left-radius: 21px;
	/* border-top-right-radius: 10px; */
	padding-right: 15px;
	width: 206px;
	height: -moz-fit-content;
	height: fit-content;
	max-height: 300px;
	/* padding: 0px 15px 10px 4.5px; */
	padding-left: 10px;
	/* border-bottom-right-radius: 10px; */
	margin-top: 10px;
	/* border-radius: 21px; */
	background-color: #0d0e12;
	padding: 100px, 100px, 0, 100px;
	align-items: center;
	z-index: 2;
	position: absolute;
	top: 33px;
	justify-content: space-between;
	transition: width 0.3s ease-in-out;

	.search-results-container {
		overflow-y: scroll;
		overflow-x: hidden;
		height: -moz-fit-content;
		height: fit-content;
		min-height: 0;
		max-height: 270px;

		&.active {
			margin: 15px 10px 15px 8px;
		}

		&.no-scroll {
			overflow-y: hidden;
		}
	}

	h4 {
		text-align: left;
		font-weight: 400;
		font-size: 16px;
		font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
		color: rgba(255, 255, 255, 0.5);
		transition: all 0.3s ease-in-out;

		&.extended {
			margin-left: 30px;
		}
	}

	&.extended {
		width: 403px;
	}

	&.scrolled {
		background-color: rgba(15, 16, 22, 0.7);
		position: fixed;
		top: 72px;
		right: 0;
		left: 0;
		width: 100%;
		height: 89px;
		z-index: 1000;
	}

	.results-div {
		display: flex;
		justify-content: space-between;
		width: 100%;
		/* left: 120px; */
		/* margin-left: 120px; */
		padding-left: calc(50% - 600px);
		padding-right: calc(50% - 630px);
		align-items: center;
	}
}

.auto-complete-search .search-results .result {
	display: flex;
	align-items: flex-start;
	text-align: left;
	color: #fff;
	margin: 20px 0;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&.extended {
		margin: 20px 0 20px 30px;
	}

	.nickname {
		margin: 10px 0 0 20px;

		mark {
			color: #beff64;
			background-color: transparent;
		}
	}
}

.auto-complete-search .search-results .result.selected {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 25px;
}

.auto-complete-search .search-results .result img {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	margin-right: 10px;
}

.auto-complete-search .search-results .result.workout {
	margin: 0;
	margin-bottom: 15px;

	.details {
		display: none;

		&.extended {
			display: flex;
			align-items: baseline;
			width: 150%;
		}
	}

	.difficulty-level {
		width: auto;
	}

	h3 {
		text-align: left;
		font-size: 12px;
		font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
		margin-top: 10px;
		margin-bottom: -10px;
		font-weight: 600;

		&.extended {
			font-size: 16px;
			margin-top: 9px;
		}
	}

	span {
		color: rgba(255, 255, 255, 0.5);
		text-align: left;
		margin: 16px 15px 5px 0;
		width: max-content;

		&.difficulty-level {
			margin-left: -11px;
		}
	}
}

.auto-complete-search .search-results .result.workout img {
	border-radius: 0;
	height: 50px;
	width: 80px;
	transition: all 0.3s ease-in-out;

	&.extended {
		margin-left: 15px;
	}
}

.auto-complete-search .no-results {
	text-align: center;
	/* margin-top: 10px; */
	padding: 15px;
	/* margin-left: 20px; */
	/* border-bottom-left-radius: 21px; */
	/* border-top-right-radius: 10px; */
	padding-right: 15px;
	width: 206px;
	height: -moz-fit-content;
	height: fit-content;
	/* max-height: 300px; */
	/* border-top-left-radius: 21px; */
	/* padding: 0px 15px 10px 4.5px; */
	padding-left: 10px;
	// border-radius-right-top: 0px;
	/* border-bottom-right-radius: 10px; */
	margin-top: 10px;
	/* border-radius: 21px; */
	background-color: #0d0e12;
	padding: 100px, 100px, 0, 100px;
	/* align-items: center; */
	z-index: 2;
	position: absolute;
	/* justify-content: space-between; */
	border-radius: 21px;
	transition: width 0.3s ease-in-out;

	&.extended {
		width: 381px;
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9998;
}

.modal .modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1001;
}

.modal .modal-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 50%);
	background-color: #fff;
	border-radius: 25px;
	text-align: center;
	color: $primary-text-color;
	opacity: 0;
	transition: all 0.3s ease;
	z-index: 100000;

	&.no-animation {
		transition: none;
	}

	&.show {
		transform: translate(-50%, -50%);
		opacity: 1;
	}

	@media screen and (max-width: $mobile-large) {
		width: 93%;
	}
}

.modal .modal-container .modal-close-button {
	position: absolute;
	top: 0;
	right: 20px;
	transform: translateY(-50%);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
	background-color: #fff;
	z-index: 1000;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	cursor: pointer;
	color: $primary-text-color;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal .modal-container.fullscreen {
	@media screen and (max-height: $landscape-height-large) and (max-width: $landscape-width-large) {
		width: 100vw;
		height: 100vh;
		overflow-y: scroll;
		overflow-x: hidden;
		border-radius: 0;
	}
}

.modal .modal-container.fullscreen .modal-close-button {
	z-index: 1500;
	width: $px20;
	height: $px20;
	top: 31px;
	right: 31px;
	background-color: transparent;
	box-shadow: none;
	border: 1px solid #000;

	.MuiSvgIcon-root {
		width: 0.78vw;
		height: 0.78vw;
	}

	@media screen and (max-width: 1400px) and (max-height: 1000px) and (min-height: 580px) {
		top: 78px;
	}

	@media screen and (max-width: $tablet-large) {
		top: 90px;
		width: 20px;
		height: 20px;

		.MuiSvgIcon-root {
			width: 15px;
			height: 15px;
		}
	}

	@media screen and (max-width: $mobile-large) {
		top: 60px;
	}

	@media screen and (max-width: $mobile-large) and (max-height: 720px) {
		top: 70px;
	}

	@media screen and (max-height: $landscape-height-large) and (max-width: $landscape-width-large) {
		top: 50px;
		right: 31px;
	}
}

.modal .modal-container .modal-side-image {
	width: 100%;
	border-top-right-radius: 35px;
	border-bottom-right-radius: 35px;
	height: 100%;
}

.modal .modal-container .modal-header {
	text-align: left;
	font-size: 30px;
	margin: 40px 50px 50px 50px;
}

.modal .modal-container .modal-body {
	margin: 30px 20px;
	max-height: calc(90vh - 300px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 40px;
}

.modal .modal-container .modal-body::-webkit-scrollbar-track {
	border-top-right-radius: 21px;
	border-bottom-right-radius: 21px;
	background-color: rgba(0, 0, 0, 0.1);
}

.modal .modal-container .modal-body::-webkit-scrollbar {
	width: 8px;
	background-color: rgba(0, 0, 0, 0.1);
}

.modal .modal-container .modal-body::-webkit-scrollbar-thumb {
	border-top-right-radius: 21px;
	border-bottom-right-radius: 21px;
	background-color: #000;
}

.modal .modal-container .modal-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 50px 50px 30px 50px;
}

.modal .modal-container .modal-footer .group {
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 10px;
}

@media screen and (max-height: 800px) {
	.modal .modal-container .modal-header {
		margin: 20px 40px 10px 40px;
	}

	.modal .modal-container .modal-body {
		margin: 20px 40px 10px 40px;
		padding: 0 10px;
	}

	.modal .modal-container .modal-footer {
		margin: 20px 40px 10px 40px;
	}
}

.modal .loader {
	position: fixed;
	top: -20px;
	left: 0;
	height: calc(100% + 20px);
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1001;
}

.modal .loader:after {
	content: "";
	position: absolute;
	border: 5px solid rgba(243, 245, 247, 0.1);
	border-top: 5px solid #ffff00;
	border-radius: 50%;
	height: 50px;
	animation: spin 2s linear infinite;
	width: 50px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 0 0;
	z-index: +1;
}

.image-cropper .image-container {
	position: relative;
	width: 300px;
	height: 300px;
}

.image-cropper .fullsize-image {
	position: fixed;
	z-index: -1;
	left: 100%;
	top: -100px;
}

.image-cropper .zoom-title {
	margin-top: 30px;
	margin-bottom: 10px;
}

.user-profile-form .footer {
	position: absolute;
	bottom: 33px;
	right: 33px;
	left: 33px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 20px;
	overflow: hidden;
}

.user-profile-form .step-content {
	opacity: 0;
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.user-profile-form .step-content.show {
	display: flex;
	opacity: 1;
}

.user-profile-form .footer .steps-counter-value {
	color: #808d9b;
	font-size: 12px;
}

.user-profile-form .footer .steps-progress-bar {
	display: flex;
	align-items: center;
	margin-bottom: 6px;
}

.user-profile-form .footer .steps-progress-bar .circle {
	position: relative;
	border-radius: 50%;
	border: solid 1px #f2f3f5;
	width: 10px;
	height: 10px;
	margin: 0 5px;
}

.user-profile-form
	.footer
	.steps-progress-bar
	.circle:not(:first-child):before {
	content: "";
	position: absolute;
	top: 50%;
	left: -12px;
	transform: translateY(-50%);
	width: 13px;
	height: 3px;
	border-top: solid 1px #f2f3f5;
	border-bottom: solid 1px #f2f3f5;
	background-color: #fff;
	z-index: +1;
}

.user-profile-form .footer .steps-progress-bar .circle.done:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #4a90e2;
	height: 6px;
	width: 6px;
	border-radius: 50%;
}

.user-profile-form .content .question {
	font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
	color: #1e2227;
	font-size: 20px;
	margin-bottom: 35px;
}

.user-profile-form .selections {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

.user-profile-form .selections > * {
	margin: 0 4px;
}

.user-profile-form .selections .option {
	border-radius: 12px;
	border: solid 1px rgba(128, 141, 155, 0.3);
	padding: 16px 16px;
	color: #808d9b;
	cursor: pointer;
}

.user-profile-form .selections .option.selected {
	color: #4a90e2;
	border: solid 1px #4a90e2;
}

.user-profile-form .selections .pill {
	border-radius: 26px;
	border: solid 1px rgba(128, 141, 155, 0.3);
	padding: 6px 16px;
	color: #808d9b;
	cursor: pointer;
}

.user-profile-form .selections .pill.selected {
	color: #4a90e2;
	border: solid 1px #4a90e2;
}

.user-profile-form .step-complition {
	position: absolute;
	top: 50%;
	bottom: 20px;
	left: 30px;
	right: 30px;
	transform: translateY(-50%);
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #1e2227;
}

.user-profile-form .step-complition .conffeti {
	width: 250px;
	height: 250px;
	min-width: 250px;
	min-height: 250px;
}

.user-profile-form .step-complition .icon svg {
	font-size: 60px;
}

.user-profile-form .step-complition .title {
	font-size: 18px;
	margin-bottom: 5px;
}

.user-profile-form .step-complition .subtitle {
	font-size: 32px;
	text-transform: uppercase;
	font-family: Gilroy-ExtraBold, Arial, Helvetica, sans-serif;
	margin-bottom: 30px;
}

.mobile .user-profile-form {
	position: absolute;
	top: 64px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
}

.mobile .user-profile-form .content {
	height: calc(100% - 100px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobile .user-profile-form .footer {
	width: 100%;
	margin: 0;
	padding: 0 20px;
	box-sizing: border-box;
}

.mobile .user-profile-form .footer .steps-counter {
	position: absolute;
	transform: translate(-50%, -50px);
	left: 50%;
}

.mobile .user-profile-form .selections {
	flex-wrap: wrap;
}

.mobile .user-profile-form .selections > * {
	margin: 8px 4px;
}

.msel {
	font-size: 20px;
	width: 40%;
	left: 30%;
	position: relative;
	text-align-last: center;
}

.msel .button {
	padding: 15px;
	background-color: green;
	width: 200px;
	cursor: pointer;
}

.activate-account-container {
}

.regular-login .activate-account-container .description {
	padding: 0;
	margin: 0;
	font-size: 20px;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

.performance-warning {
	.modal-body {
		overflow-y: hidden !important;
	}

	.modal-footer {
		@media screen and (max-width: $mobile-large) {
			margin: 20px 40px 30px 40px !important;
		}
	}
}

.performance-warning .title {
	font-family: Gilroy-Light, Arial, Helvetica, sans-serif;
	font-size: 32px;
	color: $primary-text-color;
}

.performance-warning .subtitle {
	font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #808d9b;
	margin-top: 7px;
}

.performance-warning img {
	width: 730px;
	margin-top: 65px;

	@media screen and (max-width: $mobile-large) {
		width: 100%;
		height: 150px;
		margin-top: 25px;
	}
}

@media screen and (max-width: $mobile-large) {
	.social-login .divider {
		margin: -5px 0 0;
	}

	.auto-complete-search .search-results {
		&.extended {
			width: 85vw;
			overflow-x: hidden;
		}
	}

	.regular-login {
		.title {
			margin-bottom: 10px;
		}
	}
}

@media only screen and (max-height: $landscape-height-large) and (max-width: $landscape-width-large) and (min-width: 550px) and (orientation: landscape) {
	.modal .modal-container {
		width: 343px;
		height: 265px;

		&.auth-container {
			// height: -moz-fit-content;
			// height: -webkit-fit-content;
			// height: fit-content;
			// overflow-y: scroll;
		}
	}

	.modal .modal-container .modal-body {
		margin-top: 5px;
	}

	.default-theme .logo {
		top: 25px;
		width: 15%;
		left: 50% !important;
	}

	.system-setup .camera-permission .waiting-for-camera {
		margin-top: 40px;
	}

	.countdown-timer {
		z-index: 1000;
	}

	.round-timer {
		top: 10px;
		right: 10px;
		height: 26vh;
		width: 26vh;

		.value {
			font-size: 15vh;
			font-weight: bold;
		}
	}
}

@media screen and (max-width: $tablet-medium) {
	.auto-complete-search .search-results.extended,
	.auto-complete-search .search-field.extended {
		width: 306px;
	}
}
